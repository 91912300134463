.dot1 {
    animation: 0.9s 0s infinite blink;
}

.dot2 {
    animation: 0.9s 0.3s infinite blink;
}

.dot3 {
    animation: 0.9s 0.6s infinite blink;
}

@keyframes blink {
    0% {
        background-color: #c3d6ff;
    }
    33.33% {
        background-color: #fff;
    }
    66.66% {
        background-color: #fff;
    }
    100% {
        background-color: #c3d6ff;
    }
}

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 8px;
}
