@import url("https://fonts.cdnfonts.com/css/satoshi");

body {
    margin: 0;
    font-family: "Satoshi", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
}
